import React, { lazy, Suspense, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { BasicSpinner } from './components/Loader';
import { PublicRoute } from './components/Routes';
import { CaptchaPublicRoute, PrivateRoute2 } from './components/Routes/CaptchaRoute';
import { getCookie } from './utils';

// import Tawkjs from './utils/tawk';

// Containers
const NewLayout = lazy(() => import('./containers/NewLayout'));

// Pages
const Login = lazy(() => import('./views/Auth/Login'));
const Register = lazy(() => import('./views/Auth/register'));
const RegisterPassword = lazy(() => import('./views/Auth/register-password'));
const Forgot = lazy(() => import('./views/Auth/Forgot'));
const ValidateForgot = lazy(() => import('./views/Auth/Forgot/validate'));
const LoginOTP = lazy(() => import('./views/Auth/Login/otp'));
const EmailSuccess = lazy(() => import('./views/Auth/EmailSuccess'));
const LinkFailed = lazy(() => import('./views/Auth/LinkFailed'));
const Auth = lazy(() => import('./views/Pages/Auth/Auth'));
const Page404 = lazy(() => import('./views/Pages/Page404'));
const Page500 = lazy(() => import('./views/Pages/Page500'));
const LandingPage = lazy(() => import('./views/Pages/LandingPage/LandingPage'));
const LoginOTPwReCaptcha = lazy(() => import('./views/Auth/Login/otp2'));
const isAuthenticated = getCookie('token-web-payout');

function App() {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const storedToken = getCookie('token-web-payout');
        if (isAuthenticated !== storedToken) {
          window.location.reload();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isAuthenticated]);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <BasicSpinner />
          </div>
        }
      >
        <Switch>
          <PublicRoute path='/auth' name='Login Page' component={Auth} />
          <CaptchaPublicRoute path='/register' component={Register} withReCaptcha />
          <CaptchaPublicRoute path='/login' component={Login} withReCaptcha />
          <CaptchaPublicRoute path='/forgot' component={Forgot} withReCaptcha />
          <PublicRoute path='/login-otp' name='Login OTP' component={LoginOTP} />
          <PublicRoute path='/otp' name='Login OTP' component={LoginOTPwReCaptcha} />
          <Route path={`/verification`}>
            <EmailSuccess mode={'register'} />
          </Route>
          <Route path={`/resend-email`}>
            <EmailSuccess mode={'resend'} />
          </Route>
          <Route path={`/register-password`}>
            <RegisterPassword />
          </Route>
          <Route path={`/email/failed`}>
            <LinkFailed mode='used' />
          </Route>
          <Route path={`/email/expired`}>
            <LinkFailed mode='expired' />
          </Route>
          <Route path={`/forgot-success`}>
            <EmailSuccess mode={'forgot'} />
          </Route>
          <Route path={`/email/validate`}>
            <ValidateForgot />
          </Route>
          {/* <PublicRoute path={`email/validate`} component={ValidateForgot} /> */}
          <PrivateRoute2 exact path='/' name='Landing Page' component={LandingPage} />
          <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
          <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
          <Route path='/' name='Home' render={(props) => <NewLayout {...props} />} />
          {/* <PrivateRoute patch='/create-order' exact={true} name='create-order' component={CreateOrder} permission={PERMISSION.TRANSACTION.CREATE_TRANSACTION} version='qoinhub' /> */}
        </Switch>
      </Suspense>
      <ToastContainer position='top-right' />
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, null)(App);
